<template>
  <div :style="{height: gap}"></div>
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        w: String,
        x1: Boolean,
        x2: Boolean,
        x3: Boolean,
        x4: Boolean,
        x5: Boolean,
        x6: Boolean,
      },
      computed: {
        gap(){
            if (this.x1) return '2vh'
            if (this.x2) return '4vh'
            if (this.x3) return '8vh'
            if (this.x4) return '10vh'
            if (this.x5) return '12vh'
            if (this.x6) return '14vh'
            if (this.w) return this.w
            return '5.5vh'
        },
      },
}
</script>