<template>
    
    <div class="footer">
            <div class="container">
                <div class="cols">
                    <a href="/">
                        <div class="row center">
                            <svg width="34" height="18" viewBox="0 0 34 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.37404 10.0714H29.811L26.4369 18H0L3.37404 10.0714Z" fill="#73C5FC"/><path d="M7.563 0H34L30.6259 7.92857H4.18896L7.563 0Z" fill="#F55346"/></svg>
                            <p class="brandtext">Senthy</p>
                        </div>
                    </a>
                </div>

               <!--  <div class="cols">
                    <router-link class="item" v-for="(item, index) in action" :key="index" :to="item.link">{{t(item.id)}}</router-link>
                </div>

                <div class="cols">
                    <router-link class="item" v-for="(item, index) in action" :key="index" :to="item.link">{{t(item.id)}}</router-link>
                </div>

                <div class="cols">
                    <router-link class="item" v-for="(item, index) in action" :key="index" :to="item.link">{{t(item.id)}}</router-link>
                </div> -->

                <div class="cols">
                    <p class="item">Сделать темно</p>
                    <Mode :dark="db.settings.dark" @change="db.settings.dark = !db.settings.dark"></Mode>
                </div>
            </div>

            <div class="center row">
                <p class="item wide" @click="go('about.personal')">Персональные данные</p>
                <p class="item wide" @click="go('about.offer')">Оферта</p>
                <p class="item wide" @click="go('about.user')">Пользовательское соглашение</p>
                <p class="item wide" @click="go('about.req')">Реквизиты</p>
            </div>
        
        
    </div>
    
    
</template>
<script>
import { useI18n } from 'vue-i18n'
export default {
    data: function () {return {
        action: [
            { id: 'home', link:'/'},
            { id: 'search', link:'/search'},
            { id: 'people', link:'/people'},
            { id: 'top', link:'/top'},
            { id: 'wait', link:'/wait'},
        ],
        policy: [
            { id: 'home', link:'/'},
            { id: 'search', link:'/search'},
            { id: 'people', link:'/people'},
            { id: 'top', link:'/top'},
            { id: 'wait', link:'/wait'},
        ],
    }},
    setup() {
            const { t } = useI18n({
            inheritLocale: true,
            useScope: 'local'
        })
        return { t }
    },
    components:{},
    props:{
        
    },
}
</script>

<i18n>
{
  "en": {
    "home": "Home",
    "search": "Search",
    "people": "People",
    "top": "Top 100",
    "wait": "Waitlist",
  }
}
</i18n>

<style scoped>
.footer{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding: 50px 0 20px;
    background: var(--footer);
    margin-top: 20vh;
}

.container{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1200px;
    background: none;
    margin-bottom: 100px;

}

.brandtext{
    font-style: normal;
    font-weight: 600;
    margin-left: 15px;
    color: var(--text);
}

.cols{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 50px;
    align-items: flex-start;
    justify-content: flex-start;
}

.logo{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.logo p{
    color:  var(--text);
    text-align: left;
    font-size: 11px;
    line-height: 15px;
    margin-left: 10px;
}


.item{
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0em;
    color:  var(--g4);
    text-decoration: none;
    margin: 0 0px 6px;
    white-space: nowrap;
}

.wide{
    margin: 0 10px;
}
.item.router-link-exact-active{
    opacity: .3;
}
</style>

