<template>
    <div class="desktop card">

        <div style="margin:5px 0"><slot name="up"></slot></div>
        <!-- title -->
        <a class="title readTitle" :class="{simples: simple}" :href="link">
            <img v-if="image" class="icon" :src="image"> {{title}}</a>

            <!-- <div class="row">
                <img v-if="!simple" class="icon" :src="`/images/type/`+type+`.svg?1`">
                <p>{{type}}</p>
            </div> -->
            

        <!-- user -->
        <div style="margin:5px 0"><slot name="top"></slot></div>
        
        <!-- bottom -->
        <div class="bottom column" :class="{simple:simple}"><slot name="bottom"></slot></div>
        
    </div>
        
    
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        title: String,
        type: String,
        link: String,
        simple: Boolean,
        image: String,

    },
    methods: {
       /*  onClick(){
            this.$emit('click')
        } */
    },
}
</script>
<style scoped>
    .card {
        max-width: 320px;
        /* min-width: 300px; */
        margin: 0px 5% 200px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
    }

    .title {
        color: var(--title);
        text-decoration: none;
        cursor: pointer;
        text-align: left;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 10px;

        font-style: normal;
        /* font-weight: bold; */
        font-family: 'Times New Roman', Times, serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 44px;
        line-height: 130%;
        letter-spacing: -0.02em;
    }

    .readTitle{
        font-family: Times, 'Times New Roman', Times, serif;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 100%;
        letter-spacing: -1.2px;
        color: var(--title);
    }

    .simples {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 130%;
        margin-bottom: 16px;
        letter-spacing: 0.02em;
    }

    .icon {
        margin-right: 6px;
        margin-top: 2px;
        margin-bottom: -6px;
        width: 22px;
        height: 22px;
    }

    .bottom {
        width: 80%;
        margin-left: 10%;
    }

    .simple{
        width: 100%;
        margin-left: 0;
    }

</style>