<template>

<vue-resizable
        ref="resizableComponent"
        :dragSelector="dragSelector"
        >
        <div 
            ref="target"
            class="pop" 
            :class="{center: center, fixed: fixed,absolute: !fixed,nopadding: nopadding}"
            :style="{'z-index':z, 'width':w, 'height':h}">
            <div class="headspace">
                <p class="title" v-if="title">{{title}}</p>
                <svg style="position: absolute; top: 5px;left: 9px; cursor: default;" @click="close()" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle class="pointer" cx="6" cy="6" r="6" fill="var(--g1)"/></svg>
            </div>

            <div class="wrapper">
                <div class="header"><slot name="header"></slot></div>
                <div class="body"><slot></slot></div>
                <div class="footer"><slot name="footer"></slot></div>
            </div>
        </div>  
</vue-resizable>
    <!-- 
        
        Example:

        <Popup center fixed relative v-if="popupPromo" z="1000" @close="closePromo">
            <template #header>Promocode</template>
            
              <div class="column w500">
                  <input type="text" placeholder="promocode...">
              </div>
            
            <template #footer>sfsfsf</template>
        </Popup>

     -->
     
</template>
<script>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import VueResizable from 'vue-resizable'
export default {
    components: {VueResizable},
    setup(props, { emit }) {
        //outside
        const target = ref(null)
        onClickOutside(target, (event) => emit('close', true))

        return { target}
    },
    data: function () {
        // const tW = parseInt(this.w);
        // const tH = parseInt(this.h);
        const tW = 600;
        const tH = 200;
        return {
            handlers: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
            //left: `calc( 50% - ${tW / 2}px)`,
            //top: `calc( 50% - ${tH / 2}px)`,
            height: tH,
            width: tW,
            fit: true,
            event: "",
            dragSelector: ".headspace, .footer"
    }},
    props:{
        title: String,
        sub: String,
        w: String,
        h: String,
        fixed: Boolean,
        absolute: Boolean,
        center: {type: Boolean, default: true},
        z: {type: Number, default: 1000},
        min: {type: String, default: '200px'}, 
        top: String, 
        left: String, 
        mouse:Boolean,
        nopadding: Boolean,
        screen:Boolean,
    },
    methods: {
        close(){
            this.$emit('close', true);
        },
         eHandler(data) {
            this.width = data.width;
            this.height = data.height;
            //this.left = data.left;
            //this.top = data.top;
            this.event = data.eventName;
        }
    },

    filters: {
    checkEmpty(value) {
      return typeof value !== "number" ? 0 : value;
    }
  },
}
</script>

<style scoped>
   .pop {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: flex-start;
       background: var(--back);
       /* max-width: 90vw; */
       padding: 0px 0px 0px;
        box-shadow: 40px 54px 264px rgba(0, 0, 0, 0.90);
        border-radius: 10px;
   }
   .headspace{
    display: flex;
       flex-direction: column;
       justify-content: center;
    width:100%; 
    padding: 10px 0;
    user-select: none;
   }
   .nopadding{
    padding:0
   }
   .wrapper{
       position: relative;
       display: flex;
       flex-direction: column;
       /* justify-content: space-between; */
       align-items: flex-start;
       height: 100%;
       width: 100%;
   }


   .header{
       position: relative;
       display: flex;
       flex-direction: column;
       justify-content: left;
       align-items: flex-start;
       margin-bottom: var(--s2);
       padding: 0px 30px;
   }

    .body{
        position: relative;
       display: flex;
       flex-direction: column;
       width: 100%;
       padding: 0px 30px;
   }

   .footer{
       position: relative;
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       padding: var(--s4) 0 var(--s);
       width: 100%;
       /* border-top:0.5px solid var(--g05); */
       padding: 20px 30px;
   }

   .center {
       /* top: 10vh; */
      top:50%;left:50%;transform: translate(-50%, -50%);vertical-align: middle;
       vertical-align: middle;
   }

   .absolute {
       position: absolute;
   }
   .fixed {
       position: fixed;
   }
  
    .close{
      cursor: default;
      transition: all 0.1s ease;
    }
    
    .close:hover{
      fill-opacity: 0.16;
    }

    .title{
        cursor: default;
        font-size: 14px;
        font-weight: 400;
        color: var(--g5);
        margin: 0;
        line-height: 120%;
        margin-bottom: 2px;
        user-select: none;
    }

    .sub{
        cursor: default;
        font-size: 13px;
        font-weight: 400;
        color: var(--g3);
        margin: 0;
        line-height: 140%;
        letter-spacing: .1px;
        width: 80%;
        text-align: left;
        user-select: none;
    }

    

    
</style>