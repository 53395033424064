import { mande } from 'mande';
const apipath = process.env.NODE_ENV == 'development' ? 'http://localhost:9000' : '/api'
const request = mande(process.env.apihost || apipath)


// send
const send = async function(name, req){
  console.log('req', {name, req:req})
  let resp = await request.post({name, req})
  console.log('resp', {name, resp})
  return resp
};


// api
export default {

  // accounts
  account:{ 
      async logout() {return await send('account.logout',{})},
      async promo(code) {return await send('account.promo',{code})},
  },

  async support(message, tg) {return await send('support.message',{message,tg})},

  login:{
    async email(key, token) {return await send('account.login',{provider:'email', key, token})},
    async google(token) {return await send('account.login',{provider:'google',token})},
    async apple(token) {return await send('account.login',{provider:'apple', token})},
  },

  // profiles
  profile:{ 
      async me() {return await send('account.me',{})},
      async update(k, v) { return await send('account.update',{k,v})},
  },

  // channels
  ai:{ 
    async text(promt, params) {return await send('ai.text',{promt, params})},
    async clone(image, promt) {return await send('ai.clone',{image, promt})},
    async scale(image) {return await send('ai.scale',{image})},
    async qr(promt, link) {return await send('ai.qr',{promt, link})},
  },

  // team
  admin:{ 
    users:{
      async list(limit, offset, q) {return await send('admin.users.list',{limit,offset,q})},
    },
    credits:{
      async add(id, credits,set) {return await send('admin.credits.add',{id,credits,set})},
      async list(limit, offset, q) {return await send('admin.credits.list',{limit,offset,q})},
    },
    team:{
      async add(id) {return await send('admin.team.add',{id})},
      async remove(id) {return await send('admin.team.remove',{id})},
    },
    ai:{
      async textConfig() {return await send('admin.ai.text.config',{})},
      async textConfigSave() {return await send('admin.ai.text.save',{})},
    },
    
    
  },


  async upload(filetype, file, origin){
      return new Promise(function (resolve, reject) {
        const rs = new XMLHttpRequest();
        let host = `${apipath}/upload?format=bytes&&type=${filetype}`;
        if (origin) host += `&&name=${origin}`;
        rs.open('post', host, true);
        rs.onload = function () {
          if (rs.status != 200)
            reject({ error: { id: 'try' } });
          if (!rs.response) resolve({});
          const resp = JSON.parse(rs.response);
          resolve(resp);
        };
        const uint8 = new Uint8Array(file);
        rs.send(uint8);
      });
    },
  
}




