<template>
    <!-- phone preview feed -->
    <div class="phone column">
        
        <!-- ios header -->
        <!-- <img class="header" src="/images/icons/mobile/head.svg"> -->
        
        <div class="wrap">

                <!-- has numbers -->
                <div v-if="numbers">
                    <p class="numbers" :style="{fontSize: numsize(),lineHeight: numline()}">{{numbers}}</p>
                    <p class="sub">{{title || 'news title for numbers'}}</p>
                </div>

                <!-- no numbers -->
                <p v-else class="title">{{title || 'News title'}}</p>
                
                <!-- time -->
                <p class="time">5 min ago</p>
                
                <!-- line -->
                <p v-if="line && !read" class="line">{{line}}</p>

                <Comment v-if="read" :width="width" :comment="read" line="James Miller, Editor at Read"/>
                <br>

                <!-- image -->
                <img v-if="image" class="image" :src="image">
                
                <!-- button -->
                <Buttons v-if="button" auto>{{button}}</Buttons>

                <!-- comments -->
                <svg width="45" height="25" viewBox="0 0 45 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.1924 16H34.5635V14.1782H35.8267V12.9722H34.5635V6.84033H32.5386C31.2754 8.73828 29.9297 10.9028 28.7236 12.9722V14.1782H33.1924V16ZM30.0884 12.9976V12.9087C31.0278 11.3027 32.145 9.51904 33.1289 8.04004H33.2114V12.9976H30.0884ZM37.2996 16H43.4695V14.7876H39.2356V14.6606L41.178 12.7246C42.8411 11.0742 43.3235 10.2744 43.3235 9.2334V9.21436C43.3235 7.70361 42.0667 6.61816 40.3591 6.61816C38.5183 6.61816 37.2234 7.79883 37.2171 9.47461L37.2297 9.4873H38.5628L38.5691 9.46826C38.5691 8.46533 39.2546 7.79248 40.283 7.79248C41.2859 7.79248 41.9016 8.45264 41.9016 9.34766V9.3667C41.9016 10.1094 41.5525 10.5791 40.3401 11.8423L37.2996 15.0352V16Z" fill="black" fill-opacity="0.7"/>
<path d="M5.46891 3.64587C3.60853 3.64587 2.0835 5.17091 2.0835 7.03129V15.8855C2.0835 17.7458 3.60853 19.2709 5.46891 19.2709H6.25016V22.1355C6.25016 23.1594 7.51438 23.7912 8.33349 23.1771L13.5418 19.2709H19.5314C21.3918 19.2709 22.9168 17.7458 22.9168 15.8855V7.03129C22.9168 5.17091 21.3918 3.64587 19.5314 3.64587H5.46891ZM5.46891 5.20837H19.5314C20.5471 5.20837 21.3543 6.01563 21.3543 7.03129V15.8855C21.3543 16.9011 20.5471 17.7084 19.5314 17.7084H13.2814C13.1123 17.7085 12.9477 17.7634 12.8125 17.865L7.81266 21.6146V18.4896C7.81264 18.2824 7.73032 18.0837 7.58382 17.9372C7.43731 17.7907 7.2386 17.7084 7.03141 17.7084H5.46891C4.45325 17.7084 3.646 16.9011 3.646 15.8855V7.03129C3.646 6.01563 4.45325 5.20837 5.46891 5.20837Z" fill="black" fill-opacity="0.15"/>
</svg>

        <br>
        <p class="text">{{text}} </p>
        <br>
                
        </div>
    </div>
        
    
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        numbers: String,
        title: String,
        line: String,
        read: String,
        button: String,
        image: String,
        text:String,
        width:{type:String, default:'200px'},
        editor: {type:String, default:'Read Editor'},
        
    },
    methods: {
        numsize(){
            return (300 / this.numbers.length) * 1.2 + 'px'
        },
        numline(){
            return (300 / this.numbers.length) * 1.2 + 'px'
        }
    },
}
</script>
<style scoped>

.phone{
  /* width: 320px;
  max-width: 320px; */
  background: var(--back);
  border: none;
  /* box-shadow: 10px 24px 64px rgba(0, 0, 0, 0.25);
  border-radius: 12px; */
}

/* .header{
    width: 320px;
    height: auto;
} */

.wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    /* padding:25px 25px 25px; */
    /* width: 320px; */
    text-align: left;
}
   
.numbers{
  font-family: Times, 'Times New Roman', Times, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 100%;
    letter-spacing: -1.2px;
    color: var(--title);
    margin: 0 0 8px;
}

.title{
    font-family: Times, 'Times New Roman', Times, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: -1.2px;
    color: var(--title);
}

.sub{
  font-family: Georgia, Cambria, Cochin, Times, 'Times New Roman', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 130%;
  color: var(--text);
  
}

.line{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    color: var(--g6);
    line-height: 140%;
    margin: 0 0 16px;
}
.text{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    color: var(--g8);
    line-height: 140%;
    margin: 0 0 16px;
    white-space: pre-wrap;
}

.time{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 11px;
    color: var(--g3);
    margin: 12px 0;
}



.image{
    width: 100%;
    height: auto;
    border-radius:10px;
    margin: 8px 0 20px;
}

</style>