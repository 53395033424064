<template>
        <switcher :checked="dark" @switch="togglemode()"></switcher>
</template>
<script>
export default {
    data: function () {return {}},
    props:{
      dark:Boolean,
    },
    mounted() {
      let isdark = document.querySelector('body').classList.contains('dark')
      if (this.dark != isdark){
        document.querySelector('body').classList.toggle('dark')
      }
    },
    methods: {
        togglemode(){
            document.querySelector('body').classList.toggle('dark')
            let dark = document.querySelector('body').classList.contains('dark')
            this.$emit('change', dark)
        }, 
        
    },
}
</script>
