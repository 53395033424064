<template>

    <!-- editor -->
    <div class="row" style="padding-left: 5px; minWidth:50px" :style="{maxWidth:width}" >
        
        <div class="user relative column" style="padding-left: 10px;paddingTop:5px">
            <img class="image" :src="image">
            <svg :style="{marginTop: edit ? '-4px' : '-7px', marginRight: edit ? '-1px' : '-3px'}"  width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 24V3C19.8333 7 13 14.5 2 9.5C12 25.5 19 24.5 21 24Z" :fill="shadow"/><path d="M21 19V0C19.8333 4 11 11.5 0 6.5C10.5 23 18.5 20 21 19Z" :fill="back"/></svg>
        </div>
        
        <div class="column relative" style="margin-left: -1px;">
            <p class="line">{{line}}</p>
            
                <div class="area">
                    <textarea v-if="edit" class="input font" ref="editor" rows="1" @keydown.enter.prevent="''" :style="{background:back, color:fontcolor}"  :value="modelValue" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' @input="updateValue" :placeholder="placeholder" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"></textarea>
                    <div v-else class="noinput font"  :style="{width:width, background:back, color:fontcolor}">{{comment}} </div>
                    <div class="backs" :class="{backsNoInput: !edit}" :style="{background:shadow}"></div>
                </div>
            <br>
            <div v-if="!edit" class="row">
                <div class="row likes ch mr6">
                    <svg class="mr2" width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.81217 0.645874C2.6572 0.645874 0.0830078 3.22006 0.0830078 6.37504C0.0830078 8.50846 1.69116 10.5172 3.65153 12.5772C5.6119 14.6373 8.02487 16.7237 9.9473 18.6462C10.0938 18.7926 10.2925 18.8749 10.4997 18.8749C10.7068 18.8749 10.9055 18.7926 11.052 18.6462C12.9745 16.7237 15.3874 14.6373 17.3478 12.5772C19.3082 10.5172 20.9163 8.50846 20.9163 6.37504C20.9163 3.22006 18.3421 0.645874 15.1872 0.645874C13.3765 0.645874 11.8001 1.618 10.4997 3.26937C9.19924 1.618 7.62282 0.645874 5.81217 0.645874ZM5.81217 2.20837C7.33747 2.20837 8.60712 3.02004 9.83947 4.9692C9.90994 5.08054 10.0074 5.17224 10.1229 5.23579C10.2383 5.29934 10.3679 5.33266 10.4997 5.33266C10.6314 5.33266 10.7611 5.29934 10.8765 5.23579C10.9919 5.17224 11.0894 5.08054 11.1599 4.9692C12.3922 3.02004 13.6619 2.20837 15.1872 2.20837C17.4978 2.20837 19.3538 4.06439 19.3538 6.37504C19.3538 7.62705 18.0974 9.52458 16.2166 11.501C14.5015 13.3033 12.3696 15.1862 10.4997 17.0094C8.62972 15.1862 6.4978 13.3033 4.78271 11.501C2.90194 9.52458 1.64551 7.62705 1.64551 6.37504C1.64551 4.06439 3.50153 2.20837 5.81217 2.20837Z" fill="#878787" fill-opacity="0.4"/></svg>
                    <p class="t6">983</p>
                </div>
                <div class="row comments ch">
                    <svg class="mr2" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.46842 0.645874C1.60804 0.645874 0.0830078 2.17091 0.0830078 4.03129V12.8855C0.0830078 14.7458 1.60804 16.2709 3.46842 16.2709H4.24967V19.1355C4.24967 20.1594 5.51389 20.7912 6.33301 20.1771L11.5413 16.2709H17.5309C19.3913 16.2709 20.9163 14.7458 20.9163 12.8855V4.03129C20.9163 2.17091 19.3913 0.645874 17.5309 0.645874H3.46842ZM3.46842 2.20837H17.5309C18.5466 2.20837 19.3538 3.01563 19.3538 4.03129V12.8855C19.3538 13.9011 18.5466 14.7084 17.5309 14.7084H11.2809C11.1118 14.7085 10.9472 14.7634 10.812 14.865L5.81217 18.6146V15.4896C5.81215 15.2824 5.72984 15.0837 5.58333 14.9372C5.43682 14.7907 5.23812 14.7084 5.03092 14.7084H3.46842C2.45277 14.7084 1.64551 13.9011 1.64551 12.8855V4.03129C1.64551 3.01563 2.45277 2.20837 3.46842 2.20837Z" fill="#8B8B8B" fill-opacity="0.4"/></svg>
                    <p class="t6">24</p>

                </div>
            </div>
        </div>

            
        </div>

</template>
<script>
export default {
    data: function () {return {
        
    }},
    props:{
        edit:Boolean,
        modelValue: String,

        line:{type:String, default:"Read Editor"}, 
        comment: String,
        width:{type:String, default:"200px"}, 
        image:{type:String, default:"/images/test/u13.png"}, 
        color:{type:String, default:"yellow"},
        placeholder:String,
    },
    emits: ['update:modelValue'],
    mounted() {
      this.$nextTick(() => {
        this.$refs.editor.style.height = "";
        this.$refs.editor.style.height = this.$refs.editor.scrollHeight + "px"
      })
    }, 
    methods: {
        nospaces(e){
            e.target.value = e.target.value.replace(/(\r\n|\n|\r)/gm, "");
        },
        updateValue(e) {
            let nospace = e.target.value.replace(/(\r\n|\n|\r)/gm, "");
            this.$emit('update:modelValue', nospace)
            this.$emit('change', nospace)
        },
    },  
    computed: {
        back(){
            switch (this.color) {
                case 'yellow': return '#FFF0C8'
                default: return '#FFF0C8'
            }
        },
        shadow(){
             switch (this.color) {
                case 'yellow': return '#EDE3BE'
                default: return '#EDE3BE'
            }  
        },
        fontcolor(){
            switch (this.color) {
                case 'yellow': return 'black'
                default: return 'black'
            }
            
        },

    },
}
</script>
<style scoped>

.area{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.comment{
    width: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.user{
    align-items: flex-start;
    justify-content: flex-start;
}

.image{
    /* position: absolute; */
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    margin-top: 12px;
    margin-left: -12px;
}

.line{
    margin-left: 13px;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 20px;
    color: var(--g4);
}

.font{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 135%;
}

.input {
    z-index: 1;
    padding: 10px 18px 14px 19px;
    border-radius: 20px;
    width: -moz-fit-content; 
    width: fit-content;
    overflow-y: auto;
    word-wrap:break-word
}

.noinput {
    z-index: 1;
    padding: 9px 18px 14px 19px;
    border-radius: 20px;
    z-index: 2;
}

.backs{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    margin-left: 2%;
    padding: 40px 0px 0px 0px;
    width: 97%;
    margin-bottom: 0px;
    border-radius: 30px;
}

.backsNoInput{
    margin-bottom: -4px;
}





.comment {
    user-select: none;
    cursor: pointer;
    padding: 9px 10px 12px 17px;
    border-radius: 15px;
}




[contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  opacity: .3;
}


</style>




















