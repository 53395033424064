<template>
        
              <div class="row">

                <Column border width="35vw" padding="0px 2vw 0 0vw"> 
                      <!-- <Input placeholder="Numbers" class="readTitle" v-model="news.numbers"/> -->
                      <!-- <Text placeholder="Numbers" v-tippy="{ content: 'Hi!' }" v-model="news.numbers" class="readTitle"/> -->
                      <Text placeholder="Title" v-model="news.title" class="readTitle"/>
                      <Text placeholder="Description" style="fontSize:18px" v-model="news.line" class="text"/>
                      <Text borders placeholder="Text"   v-model="news.text" class="text"/>
                      <br>
                      <h4>Settings</h4>
                      <table class="trhover">
                          <tr><td>Topic</td><td><Input clear placeholder="Ex: usElection2024, muskdivorse" v-model="news.topic"/></td></tr>
                          <tr><td>Link</td><td><Input clear placeholder="Link" v-model="news.link"/></td></tr>
                          <tr><td>Button type</td><td>
                              <div class="tabs" style="margin:0">
                                  <p class="tab" @click="news.meta.buttonType = item.id" :class="{active: news.meta.buttonType == item.id}" v-for="(item, index) in buttonTypes" :key="index" >{{item.title}} </p>
                              </div>
                            </td></tr>
                          <tr><td>Button title</td><td><Input clear placeholder="Download at AppStore" v-model="news.meta.button"/></td></tr>
                          <tr><td>Button link</td><td><Input clear placeholder="https://apple.com..." v-model="news.meta.buttonLink"/></td></tr>
                          
                          <tr><td>Image</td><td>
                            <img v-if="news.image" :src="news.image" style="width:230px;height:auto;borderRadius:15px;margin:10px">
                              <div class="row">
                              <Buttons micro>Upload cover image...</Buttons>
                              <Buttons micro @click="news.image = undefined">Delete</Buttons>
                            </div>
                          </td></tr>
                          <tr><td>Tags</td><td>control</td></tr>
                          
                          <tr><td>title</td><td>control</td></tr>
                      </table>
                      
                      <!-- 
                      <br><br>

                      <h4>News channels</h4>
                      <br>
                      <div v-if="news.channels && news.channels.length" class="row wrap">
                        <p class="tag active"  @click="tools.push(news.channels, item)" v-for="(item, index) in news.channels" :key="index">{{channels.map[item].title}}</p>
                      </div>
                      <br>
                      <div class="row wrap">
                        <p class="tag" :style="{display: tools.has(news.channels, item.id) ? 'none': ''}" @click="tools.push(news.channels, item.id )" v-for="(item, index) in channels.subs" :key="index">{{item.title}}</p>
                      </div> -->
                      
          
                </Column>



                      <Column border width="160px" cpadding="0px 30px 0px 0px">
                          <Checkbox 
                            checked
                            :title="map[item].title" small 
                            style="marginRight:12px" 
                            @switch="tools.push(news.channels, item)" 
                            v-for="(item, index) in news.channels" 
                            :key="index">
                        </Checkbox> 
                        <hr v-if="tools.len(news.channels)">
                        <Checkbox 
                            :style="{display: tools.has(news.channels, item.id) ? 'none': ''}"
                            :title="item.title" small 
                            style="marginRight:12px" 
                            @switch="tools.push(news.channels, item.id)" 
                            v-for="(item, index) in list" 
                            :key="index">
                        </Checkbox> 
                      </Column>
                

                
                  <Column controls width="260px">
                    <template #left>
                      <Tabs>
                          <Tab @click="bool.previewPage = false" :active="!bool.previewPage">Feed</Tab>
                          <Tab @click="bool.previewPage = true" :active="bool.previewPage">Page</Tab>
                        </Tabs>
                    </template>
                      
                     
                      <gap small></gap>
                      <PreviewFeed
                          v-if="!bool.previewPage"
                          :title="news.title"
                          :numbers="news.numbers"
                          :line="news.line"
                          :point="news.point"
                          :image="news.image"
                          :text="news.text"
                          :button="news.meta.button ? news.meta.button : ''">
                      </PreviewFeed>
                      <PreviewFeed
                          v-if="bool.previewPage"
                          :title="news.title"
                          :numbers="news.numbers"
                          :line="news.line"
                          :point="news.point"
                          :image="news.image"
                          :text="news.text"
                          :button="news.meta.button ? news.meta.button : ''">
                      </PreviewFeed>
                  </Column>

                  <!-- <Column width="150px" style="height:60vh">
                      <p class="text t3">JSON</p>
                      <gap small></gap>
                      <p>{{news}}</p>
                      
                  </Column> -->
              

              </div>
            
                <div class="row between">
                  <div>  </div>
                  <div class="row">
                      <Buttons width="200px" style="marginRight:4px" @click="publish()">Publish</Buttons>
                      <Buttons clear @click="cancel()">Cancel</Buttons>
                  </div>
                </div>
              
                  
        
</template>
<script>
export default {
    data: function () {return {
        news: this.newsitem
    }},
    props:{
        newsitem:{},
        map:{}, //channels
        list:[], //channels
    },
    methods: {
        publish(){
            this.$emit('update', this.news)
        },
        cancel(){
            this.$emit('cancel', this.news)
        }  

    },
}
</script>
<style scoped>
   

</style>