<template>
<div class="tabs" style="margin:0">
    <slot></slot>
</div>
</template>
<script>
  export default {
      data() {return {}},
  }
</script>
