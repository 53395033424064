<template>
    <p class="tab" :class="{active: active}"><slot></slot></p>
</template>
<script>
  export default {
      data() {return {}},
      props:{
          active: Boolean,
        },
  }
</script>
