<template>
    <div class="box">
    <div class="row between text progress"><p class="tc">{{percent}}% funded</p><p class="tc">2 days left<!-- {{end}} --></p></div>
    <div class="column progress" style="background: var(--g05)">
    <div class="progress" :style="{width: done ? '100%' : percent+'%', background: percent > 99 ? 'var(--success)' : 'var(--g1)', height: w || '4px' }"></div>
    </div>
    </div>
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        w: String,
        end: String,
        percent: Number,
    },
    computed: {
        done(){
            return this.percent > 100
        }
    },
}
</script>
<style scoped>
.box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
    width: 100%;

}
.progress{
    width: 100%;
    border-radius: 100px;
}
.text{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 127%;
    letter-spacing: 0em;
    padding: 5px 0;
}
.tc{
    color: var(--g5);
}
</style>