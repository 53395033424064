<template>
   <div :class="{
        padding: padding,
        paddings: paddings,
        row: row, 
        column: !row, 
        around: around, 
        between: between, 
        wrap: wrap,
        desktop: desktop,
        mobile: mobile,
        ch: ch,
        cw: cw,
        screen: screen,
        screen90: screen90,
        center: center,
        left: left,
        right: right,
        top: top,
        bottom: bottom,
        absolute: absolute,
      }" style="width: 100%; max-width: 100vw; display:flex; position: relative;">
      <slot></slot>
    </div>   
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        padding: Boolean,
        paddings: Boolean,
        row: Boolean,
        around: Boolean,
        between: Boolean,
        wrap: Boolean,
        ch: {type: Boolean, default: true},
        cw: {type: Boolean, default: true},
        mobile: Boolean,
        desktop: Boolean,
        center: Boolean,
        left: Boolean,
        right: Boolean,
        fixed: Boolean,
        top: Boolean,
        bottom: Boolean,
        absolute: Boolean,
        screen: Boolean,
        screen90: Boolean,
    },
}
</script>
<style scoped>
    .row{flex-direction: row;}
    .column{flex-direction: column;}
    .ch{align-items: center;}
    .cw{justify-content: center;}
    .center{align-items: center; justify-content: center;}
    .left{justify-content: flex-start;}
    .right{justify-content: flex-end;}
    .fixed{position: fixed;}
    .wrap{flex-wrap: wrap;}
    .top{top: 0;}
    .bottom{bottom: 0;}
    .absolute{position: absolute;}
    .screen{width: 100vw; height: 100vh; padding-bottom: 20vh;}

    /* desktop */
    @media (min-width: 800px){
    .mobile {display: none !important;}
    .padding{padding: 0 8px;}
    .paddings{padding: 4px 8px; }
    }

    /* mobile */
    @media (max-width: 800px) {
    .desktop { display: none !important;}
    .padding{padding: 0 7vw;}
    .paddings{padding: 2vw 7vw; }
    }
</style>