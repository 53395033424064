import api from '@/ts/api';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';


export const ProfileStore = defineStore('profile', {
  state: () => ({ 
    me : useStorage('reshape.me', {})
  }),
  
  getters: {
    admin: (state) => state.me.meta ? state.me.meta.admin : false,
  },
  
  actions: {
    
      //при логине
      async init(profile){
        if (!profile) return
          this.me = profile
      },

      // актуальный профиль
      async refresh(){
        const res = await api.profile.me()
        console.log('store/accounts/me', res)
        if (res.error) return
        if (!res.resp || !res.resp.profile) return
        this.me = res.resp.profile
    },

    
    // обновление профиля
      async update(k, v){
        const res = await api.profile.update(k, v)
        if (res.error) return res
        console.log('store/accounts/update', k, v )
        
        // profile
        switch (k) {
          case 'name'   : this.me.name    = v; return;
          case 'line'   : this.me.line    = v; return;
          case 'image'  : this.me.image   = v; return;
          default:
        }

    },

      // выход из текущего аккаунта и переключение на следующий
      async logout(){
          // удаляем куки
          this.me = {}
          const res = await api.account.logout()
          if (res.error) return res
          return
      },
     
  },
})

