<template>
    

    <!-- user -->
    <div class="userbox" :class="{stars : !stars}">
        
        <!-- image -->
        <div class="image" v-if="!simple">
            <img v-if="image" :src="image">
            <p v-else>{{letter}}</p>
        </div>

        <!-- name -->
        <div class="column">
            <div class="column cardName">
                <!-- stars -->
    <div v-if="stars" class="row stars">
            <svg class="star" v-for="(item, index) in stars" :key="index" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 0L6.53412 2.88847L9.75528 3.45492L7.48226 5.80653L7.93893 9.04508L5 7.61L2.06107 9.04508L2.51774 5.80653L0.244718 3.45492L3.46588 2.88847L5 0Z" fill="#FFA800"/></svg>
    </div>
                <p class="name">
                    {{name}}
                    <svg v-if="valid" style="margin: 0px 0px -3px 0px;" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.12889 0.411009C6.32744 0.191117 6.67256 0.191117 6.87111 0.411009L8.18643 1.86775C8.2872 1.97935 8.43284 2.03968 8.58301 2.03202L10.5432 1.93202C10.839 1.91693 11.0831 2.16097 11.068 2.45685L10.968 4.41699C10.9603 4.56716 11.0206 4.7128 11.1322 4.81357L12.589 6.12889C12.8089 6.32744 12.8089 6.67256 12.589 6.87111L11.1322 8.18643C11.0206 8.2872 10.9603 8.43284 10.968 8.58301L11.068 10.5432C11.0831 10.839 10.839 11.0831 10.5432 11.068L8.58301 10.968C8.43284 10.9603 8.2872 11.0206 8.18643 11.1322L6.87111 12.589C6.67256 12.8089 6.32744 12.8089 6.12889 12.589L4.81357 11.1322C4.7128 11.0206 4.56716 10.9603 4.41699 10.968L2.45685 11.068C2.16097 11.0831 1.91693 10.839 1.93202 10.5432L2.03202 8.58301C2.03968 8.43284 1.97935 8.2872 1.86775 8.18643L0.411009 6.87111C0.191117 6.67256 0.191117 6.32744 0.411009 6.12889L1.86775 4.81357C1.97935 4.7128 2.03968 4.56716 2.03202 4.41699L1.93202 2.45685C1.91693 2.16097 2.16097 1.91693 2.45685 1.93202L4.41699 2.03202C4.56716 2.03968 4.7128 1.97935 4.81357 1.86775L6.12889 0.411009Z" fill="var(--primary)" /><path d="M4.33325 5.41667L6.06659 7.58333L8.66659 4.33333" stroke="white" /></svg>
                </p>
                <p class="line textlimit2" :title="line">{{line}}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {return {}},
    mounted() {
        console.log(this.image)
    },
    methods: {
       
    },
    computed: {
        letter(){
            if (this.name) return this.name.charAt(0)
            return ''
        },
    },
    props:{
        image: String,
        name: String,
        line: String,
        stars: Number,
        valid: Boolean,
        simple: Boolean,
    },
}
</script>
<style scoped>
.userbox{
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 16px 0 8px;
}
.image {
    position: relative;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 100px;
    background: var(--g05);
    margin-right: 10px;
    align-items: center;
    justify-content: center;
}

.image p{
    color: var(--g3);
    font-size: 15px;
    line-height: 34px;
    font-weight: 600;
    position: absolute;
    left: 50%;transform: translateX(-50%);
}

.image img {
    width: 24px;
    height: auto;
    min-width: 24px;
    min-height: 24px;
    border-radius: 100px;
}

.name {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 0.0em;
    color: var(--g6);
    margin-bottom: 6px;
}

.line {
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.0em;
    color: var(--g4);
    overflow: hidden;
    display: -webkit-box;
    width: 65%;
}

.stars{
    margin: 0 0 4px;
}
.star{
    margin-right: -2px;
    transform: scale(.7);
}

</style>