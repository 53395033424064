<template>
  <div class="box" :style="{top: top || '', height: height || '', width: width || ''}">
    <slot></slot>
  </div>
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        title: String,
        top: String,
        width: String,
        height: String,
      },
}
</script>

<style scoped>
.box{
  display: flex;
  flex-direction: row;
  position: fixed;
  width:100vw;
  height:97vh;
  top:34px;
  left:0
}
</style>