<template>
    <div class="button click" 
        :style="{width: width, background: back, border: border}" 
        :class="{
            auto: auto,
            small: small,
            micro: micro, 
            clear: clear,
            good: good,
            big: big,
            bad: bad, 
            round: round,
            round:tag,
            flat: flat,
            disable: disable,
            link: link}">
        <img v-if="icon" :src="icon" class="icon">
        <p class="title" :class="{iconfix: icon && !auto}" :style="{color:color}" ><slot></slot></p>  
    </div>
    
</template>
<script>
  export default {
      data() {return {}},
      props:{
        
        link: Boolean,
        icon: String,
        width: String,
        
        bad: Boolean,
        good: Boolean,
        clear:Boolean,
        flat:Boolean,
        disable: Boolean,

        back:String,
        color:String,
        border:String,

        big:Boolean,
        small: Boolean,
        micro:Boolean,
        
        auto: Boolean,
        round: Boolean,

        tag: Boolean,
        
        
        },
      methods: {click(){ this.$emit('click')},},
  }
</script>

<style scoped>
.button {
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: auto;
  height: auto;
  border-radius: var(--buttonRadius);;
  padding: 12px var(--s4) 14px;
  background: var(--buttonBack);
  margin-bottom: var(--s);
  margin-right: var(--s); /* step=4px in vars */
  box-shadow: none;
}

.title {
  font-size: var(--t3);
  font-weight: 500;
  letter-spacing: 0;
  line-height:var(--t3);
  margin: 0;
  color: var(--buttonTitle);
}


.good {background: var(--good)}
.good p{color: white}

.bad {background: var(--bad)}
.bad p{color: white}

.link {background: var(--g05)}
.link p{color: var(--link)}

.flat {background: var(--g05)}
.flat p{color: var(--g8)}

.clear {box-shadow: none;background: none;border: 1.2px solid var(--g1)}
.clear p {color: var(--g8)}

.auto {min-width: 100%}

.small {padding: 4px var(--s2) 6px;}
.small p {font-size: var(--t2);line-height: 10px}

.big {padding: 18px 40px 21px 40px;}
.big p {font-size: var(--t5); font-weight: 600; line-height: 10px}

.micro {padding: 7px var(--s3) 8px;background: var(--g05);}
.micro:hover {background: var(--g1);}
.micro p {font-size: var(--t2);line-height: 10px; color:var(--g8)}

.icon {width: var(--s5);height: var(--s5);position: absolute;left: var(--s2); margin-top:1px}
.iconfix {margin-left: var(--s4)}

.round{border-radius: 200px}

.disable{
  user-select: none;
  opacity: .2;
  /* filter: grayscale(); */
}

</style>