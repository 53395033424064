<template>
      <div class="scrollBox noscroll" :class="{y:y,x:x, nowrap:nowrap}" >
             <slot></slot>
      </div>
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        y:Boolean,
        x:Boolean,
        nowrap:Boolean,
    },
    computed: {
        done(){
            return this.percent > 100
        }
    },
}
</script>
<style scoped>
    .scrollBox{display: flex;flex-direction: column;width:100%;height:100%}
    .nowrap{white-space: nowrap;}
    .noscroll {-ms-overflow-style: none; scrollbar-width: none;}
    .noscroll::-webkit-scrollbar { display: none;}
    .y{overflow-y: scroll}
    .x{overflow-x: scroll}
    .ox{overflow-x: hidden}
    .oy{overflow-y: hidden}
</style>