<template>
    <div>
        <switcher v-if="controls" small :checked="subs" @switch="subs = !subs"><p>Show subs</p></switcher>
        <br>
        <Row v-for="(ch, index) in list.filter(i => !subs && i.sub ? false : true)" @click="select(ch)" :key="index" class="noselect" :style="{marginLeft: (ch.level -1) * 10 +'px'}">
            <span class="lamp" :style="{color: ch.soon ? 'gold' : ch.active ? 'LimeGreen': 'var(--g1)'}">●</span> 
            <span class="channel cut15" :class="{selected: ch.id == current}" :style="{fontWeight: ch.level > 2 ? '400' : '600'}">{{ch.title}}</span>   
        </Row>
    </div>
</template>
<script>
import { defineComponent} from 'vue';
export default defineComponent({
  data() {return {
    current: Number,
    subs: true,
  }},
    props:{
      list:[],
      controls: Boolean,
    },
    methods: {
        select(item) {
          this.current = item.id
          this.$emit('select', item)
        },
    },
});

</script>
<style scoped>
.channel{
  font-size: 13px;
  padding: 0px 0px 1px;
  color: var(--text);
  white-space: nowrap;
}

.channel:hover{
  cursor: pointer;
  opacity: .6;
}

.selected{
  color: var(--g4);
}

.selected:hover{
  cursor: pointer;
  opacity: 1;
}

.lamp{
  margin-right:8px;
  margin-bottom: 2px;
  font-size:8px;
}
.cut15{max-width: 15ch;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

</style>