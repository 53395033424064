<template>
    <div class="down">
      <div ref='popupwrap'><slot name="in"></slot></div>
      <OnClickOutside @trigger="close">
          <div v-if="on" :style="{top: margin}" :class="[skin || 'pop', { 'right': right}]">
            <div class="popContent"><slot name="popup"></slot></div>
          </div>        
      </OnClickOutside>
    </div>
    
</template>

<script setup>import { OnClickOutside } from '@vueuse/components';</script>
<script>


  export default {
    props: {
      on: Boolean,
      right: Boolean,
      hover: Boolean,
      skin: String,
    },
    computed: {
        margin(){
          if (!this.$refs.popupwrap.clientHeight) return 0
          return this.$refs.popupwrap.clientHeight+4+'px'
        }      
    },
    data () {return {}},
    methods: {
      close(){
          this.$emit('close', true);
        }
    },
  }
</script>

<style scoped>
  .down{
    position: relative;
  }
  .pop {
      z-index: 100;
      position: absolute;
   }

   .popContent {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background: var(--back);
      padding: 10px 17px;
      box-shadow: 2px 12px 21px rgba(0, 0, 0, .3);
      border-radius: 4px;
   }
   .right{
    right:0
   }
   
</style>