import { ProfileStore } from '@/ts/store/profile';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';


const routes: Array<RouteRecordRaw> = [
  
  {path: '/',         name: 'home',       component: () => import('@/page/home.vue')},
  {path: '/premium',  name: 'premium',       component: () => import('@/page/premium.vue')},
  {path: '/pay/:id',  name: 'pay',       component: () => import('@/page/pay.vue')},
  {path: '/faq',      name: 'faq',       component: () => import('@/page/faq.vue')},
  
  //legal
  {path: '/about', children: [
    {path: 'personal',  name: 'about.personal', component: () => import(`@/page/about/personal.vue`)},
    {path: 'user',      name: 'about.user',     component: () => import('@/page/about/user.vue')},
    {path: 'offer',     name: 'about.offer',     component: () => import('@/page/about/offer.vue')},
    {path: 'req',       name: 'about.req',     component: () => import('@/page/about/req.vue')},
  ]},
  
  
  //ai pages
  // {path: '/clone',  name: 'ai.clone',   beforeEnter: [privates], component: () => import('@/page/products/clone.vue')},
  
   // air
   {path: '/admin', name: 'admin',  beforeEnter: [privates, admin], component: () => import('@/page/admin/index.vue'),
    children: [

        //admin general
        {path: 'users',   name: 'admin/users',   beforeEnter: [privates,admin], component: () => import('@/page/admin/users.vue')},
        {path: 'credits',   name: 'admin/credits',   beforeEnter: [privates,admin], component: () => import('@/page/admin/credits.vue')},
        {path: 'logs',    name: 'admin/logs',   beforeEnter: [privates,admin], component: () => import('@/page/admin/index.vue')},
        
        //ai
        {path: 'ai', name: 'admin/ai',   beforeEnter: [privates,admin], component: () => import('@/page/admin/ai/index.vue'),
          children: [
            {path: 'other',   name: 'admin/ai/other',  beforeEnter: [privates,admin], component: () => import('@/page/admin/ai/index.vue')},
        ]
      },
        
        
    ]
  },

  {path: '/404', name: '404',component: () => import('@/page/404.vue')},
  {path: '/:catchAll(.*)', redirect:'404'}

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



function publics(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (!profile.id){
    next()
  } else{
    next({ name: 'home'})
  }
}

function admin(to: any, from: any, next: any) {
  const db = ProfileStore()
  if (db.admin){
    next()
  } else{
    next({ name: 'home'})
  }
}



function privates(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (profile.id){
    next()
  } else{
    next({ name: 'welcome' })
  }
}

function loggedNotInit(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (profile.id && !profile.name){
    next({ name: 'init' })
  } else{
    next()
  }
}

// init profile
function init(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (to.name=='init'){
    if (!profile.id){
      next({ name: 'welcome'})
      return
    }
    if (profile.id && profile.name){
      next({ name: 'profile'})
      return
    }
    if (profile.id && !profile.name){
      next()
      return
    }
  } else {
    if (!profile.id){
      next({ name: 'welcome'})
      return
    }
    if (profile.id && profile.name){
      next()
      return
    }
    if (profile.id && !profile.name){
      next({ name: 'init'})
      return
    }
  }
}

export default router
