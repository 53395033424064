<template>
    <div class="column left columnPadding" :class="[{flex:flex, border: border, over: over}, over && dropclass ? dropclass : '']" :style="{padding: padding ||'', minWidth:width || '', width:width || ''}" >
                  
                  <!-- controls -->
                  <div v-if="controls" class="controls" :style="{padding:cpadding}">
                        <div class="row ch">
                            <p v-if="title" :style="{color: color || ''}" class="title">{{title}}</p>
                            <slot name="left"></slot>
                        </div>
                        <div class="row ch">
                            <slot name="right"></slot>    
                        </div>
                  </div>

                  <!-- fixed -->
                  <Scroll v-if="width" y>  
                    <div class="column" :class="{row: row, wrap: row}" :style="{flex: `0 0 ${width}`}" style="align-items: flex-start;">
                       <slot></slot>
                    </div>
                  </Scroll>

                  <!-- 100% -->
                  <Scroll v-else y>  
                    <div class="column" :class="{row: row, wrap: row}" style="flex:1; align-items: flex-start">
                      <slot></slot>
                    </div>
                  </Scroll>
    </div>
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        row: Boolean,
        border: Boolean,
        padding: String,
        controls: Boolean, //remove title etc
        flex:Boolean, //width 100%
        table: Boolean,
        width: String, //fixed if has
        title: String,
        color:String,

        notop:Boolean,
        cpadding:{type: String, default:"15px 30px 15px 0px"},
        
        over: Boolean, //on dragover
        dropclass: String, //custom dragover class
      },
      computed: {},
}
</script>

<style scoped>

.title{  
  font-family: var(--titlefonts);
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  color: var(--text);
  margin-right: 24px;
  letter-spacing:0;
  user-select: none;
}

.hovers:hover{
  opacity: .6;
}

.controls{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 0.5px solid var(--g1); */
  width: 100%;
}

.columnPadding{
  float:left;
  box-sizing: border-box; 
  position: relative;
  padding:0 0px 0 20px;
}

.border{
  border-right: 0.5px solid var(--g2);
}

.over{
  background: rgb(255, 245, 210);
  outline: 5px dotted rgb(255, 205, 26);
}
.flex{
  flex:1; 
  align-items: flex-start
}

</style>