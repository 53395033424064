<template>
   <div class="skeleton" :style="{'animation-duration': time || rand()}">
      <slot></slot>
    </div>   
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        time: String,
    },
    methods: {
        rand(){
            return (Math.random() * (5 - 1) + 1) + 's';
        },
    
    },
}
</script>
<style scoped>
    @keyframes animation {
        0% { background: var(--g01)}
        50%{ background: var(--g1)}
        100%{background: var(--g01)}
    }
    .skeleton{
        animation-name: animation;
        /* animation-duration: 2s; */
        animation-iteration-count: infinite;
    }
</style>