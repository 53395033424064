<template>
   <p class="corner"><slot></slot></p>
</template>
<script>
export default {
    data: function () {return {}},
}
</script>
<style scoped>
    .corner{
        position: fixed;
        right: 0;
        bottom: 0;
        text-align: right;
        font-size: 10px;
        background: var(--back);
        padding: 8px 14px;
        color: var(--g4);
        cursor: default;
    }
</style>