<template>
    <box>
        <Head/>
        <div class="initbox">
            <!-- <router-view></router-view> -->
            <router-view v-slot="{ Component, route }">
                <component :is="Component" :key="route.path" />
            </router-view>
            </div>
        <!-- <TabMenu icons/> -->
        <Footer/>
    </box>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'App',
    data() {return {}},
    });
</script>
<style>
    @import '@/css/x_reset.css';
    @import '@/css/base.css';
    @import '@/css/body.css';
    @import '@/css/buttons.css';
    @import '@/css/colors.css';
    @import '@/css/input.css';
    @import '@/css/tabs.css';
    @import '@/css/subtabs.css';
    @import '@/css/tag.css';
    @import '@/css/text.css';
    @import '@/css/sizes.css';
    @import '@/css/table.css';
    @import '@/css/padding.css';
    @import '@/css/local.css';
    
    

    .initbox{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100vw;
        min-height:100vh; 
        max-width: 100vw;
        /* overflow-x: hidden; */
    }

</style>