<template>
    <div class="card" :class="{vline:vline}" draggable>
        <p class="title cut">{{title}}</p>
        <p class="time">{{time}}</p>
        <p class="line cut">{{line}}</p>
        <img v-if="image" class="image" :src="image">
        <slot></slot>
    </div>
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        title: String,
        time: String,
        line: String,
        image: String,
        vline:Boolean
    },
}
</script>
<style scoped>
    .card {
        user-select: none;
        cursor: pointer;
        max-width: 220px;
        margin: 0px 30px 80px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        /* padding: 10px 15px; */
    }

    .vline{
        margin: 0px 0px 30px 0;
    }

    /* .card:hover p {
        color: var(--text);
    } */

    .title{        
        font-family: Circular, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: var(--text);
    }

    .time{
        font-weight: 400;
        font-size: 10px;
        line-height: 140%;
        color: var(--g4);
        margin: 8px 0;
    }

    .line{
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 400;
        font-size: 11px;
        line-height: 140%;
        color: var(--g5);
        margin: 0 0 16px;
    }

    .image{
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin: 0 0 16px;
    }

    .cut {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
    }


</style>