/* import { useI18n } from 'vue-i18n'
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
}) */
export default {
    category:[
      'ads',
      'art',
      'beauty',
      'blockchain',
      'business',
      'career',
      'cinema',
      'construction',
      'cooking',
      'cryptocurrency',
      'dance',
      'edu',
      'estate',
      'finance',
      'fitness',
      'health',
      'hr',
      'invest',
      'it',
      'law',
      'magic',
      'managment',
      'marketing',
      'music',
      'personal',
      'politics',
      'pr',
      'professions',
      'programming',
      'psyhology',
      'relations',
      'science',
      'seo',
      'smm',
      'sport',
      'startup',
      'transport',
      'travel',
      'venture',
    ],
    medium: ['audio','video','text','online'],  
}




/* const messages = {
  en: {
    message: {
      hello: '{0} world'
    }
  }
} */