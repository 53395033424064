<template>
   <div class="row ch" :class="{left: left, center: center}"><slot></slot></div>   
</template>
<script>
export default {
    data: function () {return {}},
    props:{
       left: {type: Boolean, default: true},
       center: Boolean,
    },
}
</script>
<style scoped>
    .row{display: flex;flex-direction: row; position: relative;}
    .ch{align-items: center;}
    .cw{justify-content: center;}
    .center{align-items: center; justify-content: center;}
    .left{justify-content: flex-start;}
</style>