<template>
    <!-- phone preview feed -->
    <div class="phone column">
        
        <div class="wrap">
                <div class="row between">
                    <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.453125 10.5391C0.453125 10.7422 0.488281 10.9297 0.558594 11.1016C0.636719 11.2734 0.753906 11.4375 0.910156 11.5938L10.0391 20.5234C10.3047 20.7891 10.625 20.9219 11 20.9219C11.2578 20.9219 11.4883 20.8594 11.6914 20.7344C11.9023 20.6172 12.0703 20.4531 12.1953 20.2422C12.3203 20.0391 12.3828 19.8125 12.3828 19.5625C12.3828 19.1875 12.2383 18.8555 11.9492 18.5664L3.72266 10.5391L11.9492 2.51172C12.2383 2.22266 12.3828 1.89453 12.3828 1.52734C12.3828 1.26953 12.3203 1.03906 12.1953 0.835938C12.0703 0.632813 11.9023 0.472656 11.6914 0.355469C11.4883 0.230469 11.2578 0.167969 11 0.167969C10.625 0.167969 10.3047 0.296875 10.0391 0.554688L0.910156 9.48438C0.753906 9.64062 0.636719 9.80469 0.558594 9.97656C0.488281 10.1484 0.453125 10.3359 0.453125 10.5391Z" fill="var(--text)"/></svg>
                    <p class="link">Source</p>
                </div>
                <!-- has numbers -->
                <div v-if="numbers">
                    <p class="numbers" :style="{fontSize: numsize(),lineHeight: numline()}">{{numbers}}</p>
                    <p class="sub">{{title || 'news title for numbers'}}</p>
                </div>

                <!-- no numbers -->
                <p v-else class="title">{{title || 'News title'}}</p>
                
                <!-- time -->
                <p class="time">5 min ago</p>
                
                <!-- line -->
                <p class="line">{{point}}</p>

                <!-- line -->
                <p class="line">{{line}}</p>

                <!-- image -->
                <img v-if="image" class="image" :src="image">
                
                <!-- button -->
                <Buttons v-if="button" auto>{{button}}</Buttons>

                <!-- comments -->
                <svg width="45" height="25" viewBox="0 0 45 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.1924 16H34.5635V14.1782H35.8267V12.9722H34.5635V6.84033H32.5386C31.2754 8.73828 29.9297 10.9028 28.7236 12.9722V14.1782H33.1924V16ZM30.0884 12.9976V12.9087C31.0278 11.3027 32.145 9.51904 33.1289 8.04004H33.2114V12.9976H30.0884ZM37.2996 16H43.4695V14.7876H39.2356V14.6606L41.178 12.7246C42.8411 11.0742 43.3235 10.2744 43.3235 9.2334V9.21436C43.3235 7.70361 42.0667 6.61816 40.3591 6.61816C38.5183 6.61816 37.2234 7.79883 37.2171 9.47461L37.2297 9.4873H38.5628L38.5691 9.46826C38.5691 8.46533 39.2546 7.79248 40.283 7.79248C41.2859 7.79248 41.9016 8.45264 41.9016 9.34766V9.3667C41.9016 10.1094 41.5525 10.5791 40.3401 11.8423L37.2996 15.0352V16Z" fill="black" fill-opacity="0.7"/>
<path d="M5.46891 3.64587C3.60853 3.64587 2.0835 5.17091 2.0835 7.03129V15.8855C2.0835 17.7458 3.60853 19.2709 5.46891 19.2709H6.25016V22.1355C6.25016 23.1594 7.51438 23.7912 8.33349 23.1771L13.5418 19.2709H19.5314C21.3918 19.2709 22.9168 17.7458 22.9168 15.8855V7.03129C22.9168 5.17091 21.3918 3.64587 19.5314 3.64587H5.46891ZM5.46891 5.20837H19.5314C20.5471 5.20837 21.3543 6.01563 21.3543 7.03129V15.8855C21.3543 16.9011 20.5471 17.7084 19.5314 17.7084H13.2814C13.1123 17.7085 12.9477 17.7634 12.8125 17.865L7.81266 21.6146V18.4896C7.81264 18.2824 7.73032 18.0837 7.58382 17.9372C7.43731 17.7907 7.2386 17.7084 7.03141 17.7084H5.46891C4.45325 17.7084 3.646 16.9011 3.646 15.8855V7.03129C3.646 6.01563 4.45325 5.20837 5.46891 5.20837Z" fill="black" fill-opacity="0.15"/>
</svg>

            <p class="text">{{text}} </p>
                
        </div>
    </div>
        
    
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        numbers: String,
        title: String,
        line: String,
        point: String,
        button: String,
        image: String,
        text:String,
    },
    methods: {
        numsize(){
            return (300 / this.numbers.length) * 1.4 + 'px'
        },
        numline(){
            return (300 / this.numbers.length) * 1.2 + 'px'
        }
    },
}
</script>
<style scoped>

.phone{
  /* width: 320px;
  max-width: 320px; */
  background: var(--back);
  border: none;
  /* box-shadow: 10px 24px 64px rgba(0, 0, 0, 0.25);
  border-radius: 12px; */
}

/* .header{
    width: 320px;
    height: auto;
} */

.wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    /* padding:25px 25px 25px; */
    /* width: 320px; */
    text-align: left;
}
   
.numbers{
    font-family: Times, 'Times New Roman', Times, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 100%;
    letter-spacing: -1.2px;
    color: var(--title);
    margin: 0 0 8px;
}

.title{
    font-family: Times, 'Times New Roman', Times, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: -1.2px;
    color: var(--title);
}

.sub{
  font-family: Georgia, Cambria, Cochin, Times, 'Times New Roman', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 130%;
  color: var(--text);
  
}

.line{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    color: var(--g6);
    line-height: 140%;
    margin: 0 0 16px;
}

.time{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 11px;
    color: var(--g3);
    margin: 12px 0;
}



.image{
    width: 100%;
    height: auto;
    border-radius:10px;
    margin: 8px 0 20px;
}

</style>