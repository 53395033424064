<template>
    <div class="row nowrap padding pointer click" @click="click()">
      <div 
          class="switcher"
          :class="{
              active: checked, 
              radio: radio, 
          }" 
          :style="{
              background: checked && color ? color : ''
          }">
      </div> 
      <p v-if="title" class="title nowrap" :class="{bold: bold && checked}">{{title}}</p>
      <p v-if="desc" class="desc"></p>
    </div>
    
</template>
<script>
/* 
  v-model
  <switcherbox v-model="item.readonly" color="green" title="Compact"></switcherbox>

  onClick
  <switcherbox :checked="item.readonly" color="green" title="Compact" @switch="item.readonly = !item.readonly"></switcherbox>
 */
export default {
    data: function () {return {}},
    props:{
        title: String,
        desc: String,
        color: String,
        checked: Boolean,
        bold:Boolean,
    },
    methods: {
        click(){ this.$emit('switch');},
    },
}
</script>

<style scoped>
    .padding{
      padding: 5px 0;
    }
    .switcher{
      cursor: pointer;
      position: relative;
      box-sizing: border-box;
      width: 14px;
      height: 14px;
      background: none;
      border: 1px solid var(--g15);
      border-radius: 4px;
    }

    .switcher:hover{
      border: 1px solid var(--g3);
    }

  .switcher.active{
    background: var(--text);
    background: var(--checkbox);
    border: 1px solid rgba(0, 0, 0, 0);
  }

  .switcher.active::after{
    content: url("data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 3.35294L3.81579 6L7.5 1' stroke='white' stroke-width='1.6' stroke-linejoin='round'/%3E%3C/svg%3E");
    color: var(--back);
    position: absolute;
    top: -9px;
    left: 2px;
  }

  .switcher.radio{
    border-radius: 100px;
  }
  .title{
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 10px;
    color: var(--text);
    margin-left: 7px;
    margin-top: 1.6px;
  }

  .title:hover{
    opacity: .6;
  }

  .bold{
    font-weight: 700;
  }
    
</style>

