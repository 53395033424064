<template>
  
    <div
      @paste="onPaste"
      class="uploadArea"
      :class="{dragging: dragging}"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
     <p v-if="title" class="uploadText">{{title}}</p>
    </div>
  
  <!-- 
      <Drop base64 @file="uploadOneFile" @files="uploadMultiFiles"></Drop>


   -->
</template>

<script>
export default {
  data() {
    return {
      dragging: false,
      files: [],
    };
  },
  props:{
    title:String,
    base64: Boolean,
  },
  
  methods: {
    onChange() {
      if (this.base64){
        this.loadBase64(this.files[0])
      } else{
        this.$emit('file', this.files[0])
      }
      
      this.$emit('files', this.files)
      //console.log(this.files)
    },
    dragover(e) {
      e.preventDefault();
      this.dragging = true;
    },
    dragleave(e) {
      e.preventDefault();
      this.dragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.files = e.dataTransfer.files;
      this.onChange();
      this.dragging = false;
    },
    loadBase64(f){
        let reader = new FileReader();
        reader.onload = (event) => {
          this.$emit('file', event.target.result)
        }
        
        reader.readAsDataURL(f);
    },

    onPaste(event) {
                    const clipboardData = event.clipboardData || window.clipboardData;
                    const items = clipboardData.items;
                    for (let i = 0; i < items.length; i++) {
                        if (items[i].type.indexOf("image") !== -1) {
                            const imageFile = items[i].getAsFile();
                            this.processImage(imageFile);
                        }
                    }
                },
                processImage(imageFile) {
                    const reader = new FileReader();
                    reader.onload = event => {
                        //this.imageDataURL = event.target.result;
                        this.$emit('file', event.target.result)
                    };
                    reader.readAsDataURL(imageFile);
                }
 
},
};
</script>
<style scope>
.uploadArea{
  cursor: pointer;
  position: relative;
  width: 50vw;
  height: 30vh;  
  background: var(--g01);;
  border: 1px dashed none;
  /* margin: 30px; */
  border-radius: 15px;
  transition: all 0.2s ease;
}

.uploadArea:hover{
  /* background: var(--g1); */
  /* border: 1px solid var(--g2); */
}

.dragging{
  border: 1px dashed var(--g4);
  background: var(--g1);
}

.uploadText{
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: var(--g4);

  top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
</style>