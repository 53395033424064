<template>
      <textarea 
          class="text"
          :class="{top:top, bottom:bottom,hover:hover,padding:padding, top:borders, bottom:borders }"
          :style="{background: back}"
          ref="editor"
          :value="modelValue" 
          v-on:input="update($event.target.value)" 
          :placeholder="placeholder" 
          :disabled="readonly"
          autocomplete="off" 
          autocorrect="off" 
          autocapitalize="off" 
          spellcheck="false"
          :rows="rows || 4"
          oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'>
      </textarea>
</template>
<script>

export default {
    data: function () {return {}},
    
    props: {
      modelValue: String,
      placeholder: String,
      top:Boolean,
      bottom:Boolean,
      borders:Boolean,
      readonly:Boolean,
      hover:Boolean,
      back:String,//back color
      padding:Boolean,
      rows: Number,
    },

    mounted() {
      this.$refs.editor.style.height = "";
        this.$refs.editor.style.height = this.$refs.editor.scrollHeight + "px"
      this.$nextTick(() => {
        this.$refs.editor.style.height = "";
        this.$refs.editor.style.height = this.$refs.editor.scrollHeight + "px"
      })
    }, 
    
    methods: {
       update(v) {
        this.$emit('update:modelValue', v)
      },
    },
}
</script>

<style scoped>
    .text{
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start; 
      padding: 10px 15px;
      /* margin: 8px 0 ; */
      /* border: 1px solid var(--g1); */
      border-radius: 10px;
      background: var(--g05);
    }

    .bordertop{
      border-top: 0.5px solid var(--g1);
    }

    .borderbottom{
      border-bottom: 0.5px solid var(--g1);
    }
    .hover{}
    textarea.hover:focus, textarea.hover:hover { background: var(--inputBackHover) ; }
    
    .padding{
      padding: 5px 10px
    }

</style>

